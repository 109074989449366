export const SAVE_MENU_ITEMS_SUCCESS = 'SAVE_MENU_ITEMS_SUCCESS'
export const SAVE_MENU_ITEMS_FAILED = 'SAVE_MENU_ITEMS_FAILED'

export const GET_MENU_ITEMS_SUCCESS = 'GET_MENU_ITEMS_SUCCESS'
export const GET_MENU_ITEMS_FAILED = 'GET_MENU_ITEMS_FAILED'

export const DELETE_MENU_ITEMS_SUCCESS = 'DELETE_MENU_ITEMS_SUCCESS'
export const DELETE_MENU_ITEMS_FAILED = 'DELETE_MENU_ITEMS_FAILED'

export const ERROR_CLEAR = 'ERROR_CLEAR'
export const SUCCESS_MESSAGE_CLEAR = 'SUCCESS_MESSAGE_CLEAR'