// TaxonomyRegistry.js
class TaxonomyRegistry {
    constructor() {
        this.taxonomies = {
            category: {
                name: "category",
                label: "Category",
                description: "A hierarchical grouping of content.",
                hierarchical: true,
            },
            tag: {
                name: "tag",
                label: "Tag",
                description: "A non-hierarchical grouping of content.",
                hierarchical: false,
            },
        }; // Store taxonomy definitions
    }

    // Register a new taxonomy
    registerTaxonomy(name, config) {
        if (!name || typeof name !== "string") {
            throw new Error("Taxonomy name must be a valid string.");
        }
        if (this.taxonomies[name]) {
            throw new Error(`Taxonomy '${name}' is already registered.`);
        }

        this.taxonomies[name] = {
            name,
            ...config,
        };
    }

    // Retrieve a taxonomy by name
    getTaxonomy(name) {
        return this.taxonomies[name] || null;
    }

    // Retrieve all taxonomies
    getAllTaxonomies() {
        return Object.values(this.taxonomies);
    }
}

// Create a singleton instance
const taxonomyRegistry = new TaxonomyRegistry();
export default taxonomyRegistry;