import axios from "axios";
import {
    COMMENT_CREATE_SUCCESS,
    COMMENT_CREATE_FAIL,
    COMMENT_GET_SUCCESS,
    COMMENT_GET_FAIL,
    COMMENT_UPDATE_FAIL,
    COMMENT_UPDATE_SUCCESS,
    COMMENT_DELETE_SUCCESS,
    COMMENT_DELETE_FAIL,
    ERROR_CLEAR,
    SUCCESS_MESSAGE_CLEAR
} from "../types/commentTypes";

import { SERVER_URL } from '../../config/keys';

// import { jwtDecode } from 'jwt-decode'

const initialState = {
    comments: [],
    successMessage: {
        create: "",
        get: "",
        update: "",
        delete: ""
    },
    errorMessage: null
};

export const CommentReducer = (state = initialState, action) => {
    const { payload, type } = action;

    if (type === COMMENT_CREATE_SUCCESS) {
        return {
            ...state,
            successMessage: {
                ...state.successMessage,
                create: payload.successMessage,
            },
            comments: payload.comments
        }
    }

    if (type === COMMENT_CREATE_FAIL) {
        return {
            ...state,
            errorMessage: payload.errorMessage,
        };
    }

    if (type === COMMENT_GET_SUCCESS) {
        return {
            ...state,
            comments: payload.comments,
            successMessage: {
                ...state.successMessage,
                get: payload.successMessage,
            },
        }
    }

    if (type === COMMENT_GET_FAIL) {
        return {
            ...state,
            errorMessage: payload.errorMessage,
        };
    }

    if (type === COMMENT_UPDATE_SUCCESS) {
        return {
            ...state,
            comments: payload.comments,
            successMessage: {
                ...state.successMessage,
                update: payload.successMessage,
            },
        }
    }

    if (type === COMMENT_UPDATE_FAIL) {
        return {
            ...state,
            errorMessage: payload.errorMessage,
        };
    }

    if (type === COMMENT_DELETE_SUCCESS) {
        return {
            ...state,
            comments: payload.comments,
            successMessage: {
                ...state.successMessage,
                delete: payload.successMessage,
            },
        }
    }

    if (type === COMMENT_DELETE_FAIL) {
        return {
            ...state,
            errorMessage: payload.errorMessage,
        };
    }

    if (type === SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            successMessage: {
                create: "",
                get: "",
                update: "",
                delete: ""
            },
        }
    }

    if (type === ERROR_CLEAR) {
        return {
            ...state,
            errorMessage: null
        }
    }

    return state;
}