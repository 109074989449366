import registryManager from "registry";

/* 
Register custom post types
*/
registryManager.registerPostType("product", {
    label: "Product",
    icon: "shopping_cart",
    description: "Items available for purchase.",
    fields: ["title", "description", "price", "sku", "stock_status", "categories"],
});

/* registryManager.registerPostType("order", {
    label: "Order",
    icon: "shopping_basket",
    description: "Details of customer orders.",
    fields: ["order_id", "customer_name", "order_date", "total_amount", "status"],
}); */


/* 
Register custom post status
*/
registryManager.registerPostStatus("archived", {
    name: "archived",
    label: "Archived",
    description: "A post that is no longer active but kept for reference.",
});


/* 
Register custom user role
*/
registryManager.registerUserRole("editor", {
    name: "editor",
    label: "Editor",
    description: "A user who can edit content but cannot delete it.",
    permissions: ["read", "write"],
});


/* 
Register custom taxonomy
*/
registryManager.registerTaxonomy("product-category", {
    name: "product-category",
    label: "Product Category",
    description: "A grouping of related posts in a product category.",
    hierarchical: false,
});

registryManager.registerTaxonomy("product-tag", {
    name: "product-tag",
    label: "Product Tag",
    description: "A grouping of related posts in a product tag.",
    hierarchical: false,
});

// Export the registry manager for use globally
export default registryManager;