import React from 'react'
import './menuStyle.css'
import { useLayoutEffect } from 'react';
import { Box, styled, Grid, Button, FormLabel, Snackbar, Alert, Paper } from '@mui/material';
import { useRef } from 'react';
import { useEffect } from 'react';
import { Breadcrumb, SimpleCard } from 'app/components';
import MenuItemAccordian from './MenuItemAccordian';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteMenu, GetMenus, CreateMenu, UpdateMenu } from 'store/actions/menuAction';
import MenuInput from './MenuInput';
import { SUCCESS_MESSAGE_CLEAR } from 'store/types/userTypes';
import Swal from 'sweetalert2'
import MetaGenerator from 'app/components/MetaGenerator';

import MenuBuilder from "react-dnd-menu-builder";
import { GetPosts } from 'store/actions/postAction';

const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
}));

const MenuDesign = () => {
    const [menus, setMenus] = useState([]);
    const [formData, setFormData] = useState(initialFormData);

    const {
        successMessage,
        errorMessage,
        allmenus,
        current
    } = useSelector((state) => state.Menus);

    const dispatch = useDispatch();
    const appendData = useRef(null);

    const [callbackResponse, setCallbackResponse] = useState({ type: "info", message: "" });
    const [editMenuItem, setEditMenuItem] = useState({});

    const [menuData, setMenuData] = useState({
        _id: '',
        menuName: 'Create new menu',
        menuStructure: ''
    })

    let vertical = 'top';
    let horizontal = 'right';

    useLayoutEffect(() => {
        dispatch(GetPosts());
        dispatch(GetMenus());
    }, []);

    const handleSaveMenu = () => {
        /* Check if menu name is empty */
        if (menuData && Object.keys(menuData).length > 0 && (menuData.menuName.length === 0 || menuData.menuName === "Create new menu")) {
            setCallbackResponse({ type: "error", message: "Enter a menu name" });
            return;
        }

        /* Check if menu structure is empty */
        if (menus && menus.length === 0) {
            setCallbackResponse({ type: "error", message: "Add items to your menu" });
            return;
        }

        console.log({ ...menuData, "menuStructure": JSON.stringify(menus) });

        if (menuData._id === '') {
            dispatch(CreateMenu({ ...menuData, "menuStructure": JSON.stringify(menus) }));
        } else {
            dispatch(UpdateMenu({ ...menuData, "menuStructure": JSON.stringify(menus) }));
        }
    };

    useEffect(() => {
        if (successMessage && successMessage?.create?.length) {
            setCallbackResponse({ type: "success", message: successMessage.create });
            setMenuData({ ...menuData, _id: current?._id });
            dispatch({ type: SUCCESS_MESSAGE_CLEAR });
        }

        if (successMessage && successMessage?.update?.length) {
            setCallbackResponse({ type: "success", message: successMessage.update });
            setMenuData({ ...menuData, _id: current?._id });
            dispatch({ type: SUCCESS_MESSAGE_CLEAR });
        }

        if (successMessage && successMessage?.delete?.length) {
            setCallbackResponse({ type: "success", message: successMessage.delete });

            setMenuData({
                _id: '',
                menuName: 'Create new menu',
                menuStructure: ''
            });

            setMenus([]);

            dispatch({ type: SUCCESS_MESSAGE_CLEAR });
        }
    }, [successMessage]);

    return (
        <Container >
            <MetaGenerator title="Menu design" />

            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: "Dashboard", path: "/" }, { name: "Menu Design" }]} />
            </Box>

            <Snackbar anchorOrigin={{ vertical, horizontal }} open={callbackResponse.message} autoHideDuration={6000} onClose={() => setCallbackResponse({ type: "", message: "" })}>
                <Alert
                    severity={callbackResponse.type}
                    variant="filled"
                    sx={{ width: '300px' }}
                >
                    {callbackResponse.message}
                </Alert>
            </Snackbar>

            <SimpleCard title="Add menu items">
                <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={4} xs={12} sx={{ mt: 2 }}>
                        <MenuItemAccordian setCallbackResponse={setCallbackResponse} menus={menus} setMenus={setMenus} formData={formData} setFormData={setFormData} />
                        <Paper style={{ display: "none" }} id="menu-editor" sx={{ p: 2, mt: 2 }}>
                            <form>
                                <label className="form-label">Editing "<span id="currentEditName" />"</label>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="editInputName"
                                        placeholder="Item name"
                                        required=""
                                    />
                                </div>
                                <div>&nbsp;</div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="editInputSlug"
                                        placeholder="item-slug"
                                    />
                                </div>
                                <div>&nbsp;</div>
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <button className="btn btn-primary" id="editButton">
                                        Save
                                    </button>
                                    <button className="btn btn-danger" onClick={(e) => document.getElementById("menu-editor").style.display = "none"}>
                                        Cancel
                                    </button>
                                </Box>
                            </form>
                        </Paper>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={12} sx={{ mt: 2 }}>
                        <MenuInput allmenus={allmenus} menuData={menuData} setMenuData={setMenuData} menus={menus} setMenus={setMenus} />

                        <div>&nbsp;</div>
                        <label className="form-label">Structure</label>
                        <div className="dd nestable" id="nestable">
                            <MenuBuilder items={menus} setItems={setMenus} />
                        </div>
                        <div className='d-flex pull-right'>
                            <div>&nbsp;</div>
                            <button className='btn btn-primary mt-4' onClick={handleSaveMenu} style={{ width: '200px' }} >Save Menu</button>

                            <button className='btn btn-danger mt-4' onClick={() => {
                                if (menuData._id === '') {
                                    setMenuData({
                                        _id: '',
                                        menuName: 'Create new menu',
                                        menuStructure: '',
                                    });
                                    setMenus([]);
                                } else {
                                    Swal.fire({
                                        title: "Are you sure?",
                                        showCancelButton: true,
                                        text: "This action cannot be undone!",
                                        confirmButtonText: 'Yes',
                                        cancelButtonText: `No`,
                                        cancelButtonColor: "#EE7364"
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            dispatch(DeleteMenu(menuData._id))
                                        }
                                    });
                                }
                            }} style={{ width: '200px', marginLeft: '15px' }} >{menuData && menuData._id === '' ? 'Clear Menu' : 'Delete Menu'}</button>
                        </div>
                    </Grid>
                </Grid>
            </SimpleCard>
        </Container >
    )
}

export default MenuDesign

const initialMenus = [
    {
        id: "Home",
        name: "Home",
        href: "/home",
        children: [],
    },
    {
        id: "Collections",
        href: "/collections",
        name: "Collections",
        children: [
            {
                id: "Spring",
                name: "Spring",
                href: "/spring",
                children: [],
            },
        ],
    },
];

const initialFormData = {
    id: "",
    name: "",
    href: "",
    children: [],
};