// PostTypeRegistry.js
class PostTypeRegistry {
    constructor() {
        this.postTypes = {
            post: {
                name: "post",
                label: "Post",
                icon: "assignment",
                description: "A collection of blog posts.",
                fields: ["title", "content", "slug", "author", "date", "categories", "tags", "featured_image", "custom_fields"],
            },
            page: {
                name: "page",
                label: "Page",
                icon: "pages",
                description: "Static content pages.",
                fields: ["title", "content", "slug", "author", "date", "categories", "tags", "featured_image", "custom_fields"],
            },
            block: {
                name: "block",
                label: "Block",
                icon: "widgets",
                description: "Reusable blocks for building content.",
                fields: ["title", "content", "block_type", "settings"],
            },
        }; // Store post type definitions
    }

    // Register a new post type
    registerPostType(name, config) {
        if (!name || typeof name !== "string") {
            throw new Error("Post type name must be a valid string.");
        }
        if (this.postTypes[name]) {
            throw new Error(`Post type '${name}' is already registered.`);
        }

        this.postTypes[name] = {
            name,
            ...config,
        };
    }

    // Retrieve a post type by name
    getPostType(name) {
        return this.postTypes[name] || null;
    }

    // Retrieve all post types
    getAllPostTypes() {
        return Object.values(this.postTypes);
    }
}

// Create a singleton instance
const postTypeRegistry = new PostTypeRegistry();
export default postTypeRegistry;