// PostStatusRegistry.js
class PostStatusRegistry {
    constructor() {
        this.postStatuses = {
            draft: {
                name: "draft",
                label: "Draft",
                description: "A post that is saved but not published.",
            },
            pending: {
                name: "pending",
                label: "Pending Review",
                description: "A post that is awaiting review before publication.",
            },
            publish: {
                name: "publish",
                label: "Publish",
                description: "A post that is live and visible to the public.",
            },
        }; // Store post status definitions
    }

    // Register a new post status
    registerPostStatus(name, config) {
        if (!name || typeof name !== "string") {
            throw new Error("Post status name must be a valid string.");
        }
        if (this.postStatuses[name]) {
            throw new Error(`Post status '${name}' is already registered.`);
        }

        this.postStatuses[name] = {
            name,
            ...config,
        };
    }

    // Retrieve a post status by name
    getPostStatus(name) {
        return this.postStatuses[name] || null;
    }

    // Retrieve all post statuses
    getAllPostStatuses() {
        return Object.values(this.postStatuses);
    }
}

// Create a singleton instance
const postStatusRegistry = new PostStatusRegistry();
export default postStatusRegistry;