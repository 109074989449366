import postTypeRegistry from "./postType";
import postStatusRegistry from "./postStatus";
import userRoleRegistry from "./userRole";
import taxonomyRegistry from "./taxonomy";

class RegistryManager {
    constructor() {
        this.postTypes = postTypeRegistry;
        this.postStatuses = postStatusRegistry;
        this.userRoles = userRoleRegistry;
        this.taxonomies = taxonomyRegistry;
    }

    // Register a new post type
    registerPostType(name, config) {
        this.postTypes.registerPostType(name, config);
    }

    // Register a new post status
    registerPostStatus(name, config) {
        this.postStatuses.registerPostStatus(name, config);
    }

    // Register a new user role
    registerUserRole(name, config) {
        this.userRoles.registerUserRole(name, config);
    }

    // Register a new taxonomy
    registerTaxonomy(name, config) {
        this.taxonomies.registerTaxonomy(name, config);
    }

    // Retrieve all global registries
    getGlobalRegistries() {
        return {
            postTypes: this.postTypes.getAllPostTypes(),
            postStatuses: this.postStatuses.getAllPostStatuses(),
            userRoles: this.userRoles.getAllUserRoles(),
            taxonomies: this.taxonomies.getAllTaxonomies(),
        };
    }
}

// Create a singleton instance of the registry manager
const registryManager = new RegistryManager();

export default registryManager;