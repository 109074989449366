import { APP_NAME } from 'config/keys'
import React from 'react'
import { Helmet } from 'react-helmet'

export default function MetaGenerator({ title }) {
    return (
        <Helmet>
            <title>{APP_NAME} {title}</title>
        </Helmet>
    )
}
