// UserRoleRegistry.js
class UserRoleRegistry {
    constructor() {
        this.userRoles = {
            user: {
                name: "user",
                label: "User",
                description: "A regular user with limited access.",
                permissions: ["read", "write", "delete"],
            },
            admin: {
                name: "admin",
                label: "Admin",
                description: "An administrator with full access to the system.",
                permissions: ["read", "write", "delete"],
            },
        }; // Store user role definitions
    }

    // Register a new user role
    registerUserRole(name, config) {
        if (!name || typeof name !== "string") {
            throw new Error("User role name must be a valid string.");
        }
        if (this.userRoles[name]) {
            throw new Error(`User role '${name}' is already registered.`);
        }

        this.userRoles[name] = {
            name,
            ...config,
        };
    }

    // Retrieve a user role by name
    getUserRole(name) {
        return this.userRoles[name] || null;
    }

    // Retrieve all user roles
    getAllUserRoles() {
        return Object.values(this.userRoles);
    }
}

// Create a singleton instance
const userRoleRegistry = new UserRoleRegistry();
export default userRoleRegistry;