import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

import {
    Box,
    Divider,
    Typography,
    AccordionSummary,
    AccordionDetails,
    Accordion,
    TextField
} from '@mui/material'
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
// import { useState } from 'react';

export default function MenuItemAccordian({ setCallbackResponse, menus, setMenus, formData, setFormData }) {
    const { posts, postmeta } = useSelector((state) => state.Posts);
    // console.log(posts);
    const [accordianData, setAccordianData] = useState([])
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const addMenuItem = (addMenuItem, custom) => {
        let navName, navHref;

        if (custom) {
            const { name, href, _id } = addMenuItem;
            navName = name;
            navHref = href;
        } else {
            const { postSlug, postTitle, _id } = addMenuItem;
            navName = postTitle;
            navHref = postSlug;
        }

        if (navName === "" || navHref === "") {
            setCallbackResponse({ type: "error", message: "Name and URL are required." });
            return false;
        }

        setMenus([
            ...menus,
            {
                ...formData,
                id: Math.random().toString(36).substring(7),
                name: navName,
                href: navHref
            },
        ]);

        setFormData({ id: "", name: "", href: "", children: [] });
    }

    useEffect(() => {
        if (posts && posts.length) {
            const allPostTypes = [...new Set(posts.map(post => post.postType))]
                .filter(postType => postType !== "Block" && postType !== "Media");

            const organizedData = posts
                .filter(post => allPostTypes.includes(post.postType))
                .reduce((acc, post) => {
                    const existingEntry = acc.find(entry => Object.keys(entry)[0] === post.postType);

                    if (existingEntry) {
                        if (post.postStatus !== 'Draft')
                            existingEntry[post.postType].push(post);
                    } else {
                        if (post.postStatus !== 'Draft')
                            acc.push({ [post.postType]: [post] });
                    }
                    return acc;
                }, []);
            setAccordianData(organizedData);
        }
    }, [posts, postmeta]);

    return (
        <Box>
            {accordianData && accordianData.length > 0 && accordianData.map((item, key) => {
                return item && Object.keys(item).length > 0 &&
                    (<Accordion expanded={expanded === Object.keys(item)[0]} onChange={handleChange(Object.keys(item)[0])} key={key}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ flexShrink: 0 }}>
                                {Object.keys(item)[0]}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className='custom-scrollbar' sx={{ backgroundColor: "#FAFAFA", p: 1, maxHeight: '300px', overflowY: 'auto' }}>
                            {item[Object.keys(item)[0]].map(((accorItem, i) => {
                                return accorItem && Object.keys(accorItem).length > 0 && accorItem.postStatus !== 'Draft' && (
                                    <Box key={i} sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        backgroundColor: '#fff',
                                        p: 0.5, mb: 1,
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: '#f0f0f0', // Change to desired hover color
                                        },
                                    }}>
                                        <Typography sx={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {accorItem.postTitle/* .slice(0, 59) + "..." */}
                                        </Typography>
                                        <AddIcon onClick={() => addMenuItem(accorItem)} sx={{ cursor: 'pointer' }} />
                                    </Box>
                                )
                            }))}
                        </AccordionDetails>
                    </Accordion>)
            })}
            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography sx={{ color: 'green' }} >Add custom menu item</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <div className="form-group">
                            <TextField
                                id="addInputName"
                                label="Navigation Label"
                                variant="outlined"
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                                autoComplete="off"
                                InputLabelProps={{
                                    shrink: true, // Ensure the label shrinks
                                }}
                                fullWidth={true} />
                        </div>
                        <div>&nbsp;</div>
                        <div className="form-group">
                            <TextField
                                id="addInputSlug"
                                label="Navigation Url"
                                variant="outlined"
                                type="text"
                                name="href"
                                value={formData.href}
                                onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                                autoComplete="off"
                                InputLabelProps={{
                                    shrink: true, // Ensure the label shrinks
                                }}
                                fullWidth={true} />
                        </div>
                        <div>&nbsp;</div>
                        <button className="btn btn-primary" onClick={() => addMenuItem(formData, true)} style={{ width: "100%" }}>
                            Add Menu Item
                        </button>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}