import axios from "axios";
import {
  SETTING_GET_SUCCESS,
  SETTING_GET_FAIL,
  SETTING_UPDATE_FAIL,
  SETTING_UPDATE_SUCCESS,
  ERROR_CLEAR,
  SUCCESS_MESSAGE_CLEAR,
  TEST_MAIL_SUCCESS,
  TEST_MAIL_FAIL
} from "../types/settingTypes";

import { SERVER_URL } from '../../config/keys';
import { update } from "lodash";

const initialState = {
  settings: null,
  successMessage: {
    get: "",
    update: ""
  },
  errorMessage: null
};

export const SettingReducer = (state = initialState, action) => {
  const { payload, type } = action;

  if (type === SETTING_GET_SUCCESS) {
    return {
      ...state,
      settings: payload.settings && payload.settings.length > 0 ? payload.settings[0] : null,
      successMessage: {
        ...state.successMessage,
        get: payload.successMessage,
      },
    }
  }

  if (type === SETTING_GET_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
    };
  }

  if (type === SETTING_UPDATE_SUCCESS) {
    return {
      ...state,
      settings: payload.settings && payload.settings.length > 0 ? payload.settings[0] : null,
      successMessage: {
        ...state.successMessage,
        update: payload.successMessage,
      },
    }
  }

  if (type === SETTING_UPDATE_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
    };
  }

  if (type === SUCCESS_MESSAGE_CLEAR) {
    return {
      ...state,
      successMessage: {
        get: "",
        update: ""
      },
    }
  }

  if (type === ERROR_CLEAR) {
    return {
      ...state,
      errorMessage: null
    }
  }

  if (type === TEST_MAIL_SUCCESS) {
    return {
      ...state,
      successMessage: {
        ...state.successMessage,
        update: payload.successMessage
      }
    }
  }
  if (type === TEST_MAIL_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage
    }
  }

  return state;
}