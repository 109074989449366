import * as React from 'react';
import { Box, InputLabel, MenuItem, FormControl, Select, TextField } from '@mui/material';
import { useEffect } from 'react';

const MenuInput = ({ allmenus, menuData, setMenuData, menus, setMenus }) => {
    // console.log(allmenus);
    const handleChange = (e) => {
        // console.log("on menu name change: ", menuData);
        const { name, value } = e.target;
        setMenuData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSelectChange = (selectedItem) => {
        // console.log("on menu selection change: ", selectedItem);
        setMenuData({
            _id: selectedItem._id ? selectedItem._id : '',
            menuName: selectedItem.menuName ? selectedItem.menuName : 'Create new menu',
            menuStructure: selectedItem.menuStructure ? selectedItem.menuStructure : ''
        });

        if (selectedItem.menuName !== 'Create new menu') {
            setMenus(JSON.parse(selectedItem.menuStructure));
        } else {
            setMenus([]);
        }
    };

    return (
        <Box className="form-group d-flex gap-2">
            <FormControl fullWidth sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                <InputLabel id="select-menu-label">Select Menu</InputLabel>
                <Select
                    labelId="select-menu-label"
                    id="select-menu-label"
                    value={allmenus.find(item => item._id === menuData._id) || ''}
                    label="Select menu"
                    name="menuLinkWith"
                    onChange={(e) => handleSelectChange(e.target.value)}
                    sx={{ width: "48%" }}
                >
                    {allmenus && Array.isArray(allmenus) && allmenus.map((data, i) => (
                        <MenuItem key={i} value={data}>
                            {data.menuName}
                        </MenuItem>
                    ))}
                </Select>

                <TextField
                    id="outlined-basic"
                    label="Menu Name"
                    variant="outlined"
                    type="text"
                    name="menuName"
                    value={menuData.menuName === "Create new menu" ? "" : menuData.menuName}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                    autoComplete="off"
                    InputLabelProps={{
                        shrink: true, // Ensure the label shrinks
                    }}
                    sx={{ width: "48%" }} />
            </FormControl>
        </Box>
    );
};

export default MenuInput;