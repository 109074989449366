import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';
import Media from './views/media/Media';
import Page from 'frontend/Page';
import AllComments from './views/comments/AllComments';
import CommentForm from './views/comments/CommentForm';
import TestPage from './views/TestPage';
import InvoiceDetails from './views/ecommerce/invoice/InvoiceDetails';
import InvoiceList from './views/ecommerce/invoice/InvoiceList';
import OrderList from './views/ecommerce/OrderList';
import MenuDesign from './views/menu-design/MenuDesign';
import registryManager from "registry/myregistry";

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('app/views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('app/views/sessions/ResetPassword')));

// echart page
const AppEchart = Loadable(lazy(() => import('app/views/charts/echarts/AppEchart')));

// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));

// posts pages
const AllPosts = Loadable(lazy(() => import('app/views/posts/AllPosts')));
const PostForm = Loadable(lazy(() => import('app/views/posts/PostForm')));

// users pages
const AllUsers = Loadable(lazy(() => import('app/views/users/AllUsers')));
const UserForm = Loadable(lazy(() => import('app/views/users/UserForm')));

// general settings page
const Settings = Loadable(lazy(() => import('app/views/settings/Settings')));

// profile page
const Profile = Loadable(lazy(() => import('app/views/profile/Profile')));

const GlobalRegistries = registryManager.getGlobalRegistries();
// console.log(GlobalRegistries.postTypes);

const postRoutes = [];
const postByTermsRoutes = [];
const newPostRoutes = [];
const termRoutes = [];

GlobalRegistries.postTypes.map(postType => {
  postRoutes.push({
    path: `/posts/${postType.name}`,
    element: <AllPosts type={postType.name} />,
    auth: authRoles.admin
  });

  postByTermsRoutes.push({
    path: `/posts/${postType.name}/:termid`,
    element: <AllPosts type={postType.name} />,
    auth: authRoles.admin
  });

  newPostRoutes.push({
    path: `/new-post/${postType.name}`,
    element: <PostForm />,
    auth: authRoles.admin
  });
});

GlobalRegistries.taxonomies.map(taxonomy => {
  termRoutes.push({
    path: `/terms/${taxonomy.name}`,
    element: <AllPosts type={taxonomy.name} />,
    auth: authRoles.admin
  })
});

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,

      // dashboard route
      {
        path: '/',
        element: <Analytics />,
        auth: authRoles.admin
      },

      // posts routes
      ...postRoutes,

      // posts by term routes
      ...postByTermsRoutes,

      // new post routes
      ...newPostRoutes,

      // term routes
      ...termRoutes,

      // edit post
      {
        path: '/edit-post/:postid',
        element: <PostForm />,
        auth: authRoles.admin
      },

      // comments
      {
        path: '/comments',
        element: <AllComments />,
        auth: authRoles.admin
      },

      // comments per post
      {
        path: '/comments/:postid',
        element: <AllComments />,
        auth: authRoles.admin
      },

      // edit comments
      {
        path: '/edit-comment/:commentid',
        element: <CommentForm />,
        auth: authRoles.admin
      },

      // users route
      {
        path: '/users/',
        element: <AllUsers />,
        auth: authRoles.admin
      },

      // new user
      {
        path: '/new-user/',
        element: <UserForm />,
        auth: authRoles.admin
      },

      // edit user
      {
        path: '/edit-user/:userid',
        element: <UserForm />,
        auth: authRoles.admin
      },

      // general settings
      {
        path: '/settings/',
        element: <Settings />,
        auth: authRoles.admin
      },

      // profile
      {
        path: '/profile/',
        element: <Profile />,
        auth: authRoles.admin
      },

      // media
      {
        path: '/media/',
        element: <Media />,
        auth: authRoles.admin
      },

      // menu design
      {
        path: '/menu-design',
        element: <MenuDesign />,
        auth: authRoles.admin
      },

      // orders
      {
        path: '/orders/',
        element: <AllPosts type="order" />,
        // element: <OrderList />,
        auth: authRoles.admin
      },

      // order details
      {
        path: '/order-details/:orderid',
        element: <InvoiceDetails />,
        auth: authRoles.admin
      },

      // new order
      {
        path: '/new-order',
        element: <InvoiceDetails />,
        auth: authRoles.admin
      },

      // test page
      {
        path: '/test/',
        element: <TestPage />,
        auth: authRoles.admin
      }
    ]
  },

  // session pages route
  { path: '/404', element: <NotFound /> },
  { path: '/signin', element: <JwtLogin /> },
  // { path: '/signup', element: <JwtRegister /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/reset-password/:token', element: <ResetPassword /> },

  // { path: '/', element: <Navigate to="admin/dashboard/" />, auth: authRoles.admin },
  // { path: '*', element: <NotFound /> }

  { path: '/', element: <Page />, auth: authRoles.admin },
];

export default routes;