import axios from "axios";
import {
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_GET_SUCCESS,
  USER_GET_FAIL,
  USER_LOGOUT_FAIL,
  USER_LOGOUT_FAIL_CLEAR,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_SUCCESS_CLEAR,
  USER_UPDATE_FAIL,
  USER_UPDATE_SUCCESS,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  ERROR_CLEAR,
  SUCCESS_MESSAGE_CLEAR,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL
} from "../types/userTypes";

import { SERVER_URL } from '../../config/keys';

// import { jwtDecode } from 'jwt-decode'

const initialState = {
  users: null,
  successMessage: {
    login: "",
    create: "",
    get: "",
    update: "",
    delete: ""
  },
  errorMessage: null,
  authenticate: false,
  logoutSuccess: null,
  logoutError: null,
  myInfo: null,
};

export const UserReducer = (state = initialState, action) => {
  const { payload, type } = action;

  if (type === USER_LOGIN_SUCCESS) {
    localStorage.setItem("auth", true);
    return {
      ...state,
      successMessage: {
        ...state.successMessage,
        login: payload.successMessage,
      },
      myInfo: payload.currentUser,
      authenticate: true,
    }
  }

  if (type === USER_LOGIN_ERROR) {
    return {
      ...state,
      errorMessage: payload.error
    }
  }

  if (type === USER_CREATE_SUCCESS) {
    return {
      ...state,
      successMessage: {
        ...state.successMessage,
        create: payload.successMessage,
      },
      users: payload.users
    }
  }

  if (type === USER_CREATE_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
    };
  }

  if (type === USER_GET_SUCCESS) {
    return {
      ...state,
      users: payload.users,
      successMessage: {
        ...state.successMessage,
        get: payload.successMessage,
      },
    }
  }

  if (type === USER_GET_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
    };
  }

  if (type === USER_UPDATE_SUCCESS) {
    return {
      ...state,
      users: payload.users,
      successMessage: {
        ...state.successMessage,
        update: payload.successMessage,
      },
    }
  }

  if (type === USER_UPDATE_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
    };
  }

  if (type === USER_DELETE_SUCCESS) {
    return {
      ...state,
      users: payload.users,
      successMessage: {
        ...state.successMessage,
        delete: payload.successMessage,
      },
    }
  }

  if (type === USER_DELETE_FAIL) {
    return {
      ...state,
      errorMessage: payload.errorMessage,
    };
  }

  if (type === SUCCESS_MESSAGE_CLEAR) {
    return {
      ...state,
      successMessage: {
        login: "",
        create: "",
        get: "",
        update: "",
        delete: "",
        logout: null,
      },
    }
  }

  if (type === ERROR_CLEAR) {
    return {
      ...state,
      errorMessage: null
    }
  }

  if (type === USER_LOGOUT_SUCCESS) {
    return {
      ...state,
      logoutSuccess: payload.successMessage,
      authenticate: false,
      myInfo: null,
    }
  }

  if (type === USER_LOGOUT_FAIL) {
    return {
      ...state,
      logoutError: payload.errorMessage
    }
  }

  if (type === USER_LOGOUT_SUCCESS_CLEAR) {
    return {
      ...state,
      logoutSuccess: null
    }
  }

  if (type === USER_LOGOUT_FAIL_CLEAR) {
    return {
      ...state,
      logoutError: null
    }
  }

  if (type === FORGOT_PASSWORD_SUCCESS) {
    return {
      ...state,
      // users: payload.users,
      successMessage: {
        ...state.successMessage,
        update: payload.successMessage,
      },
    }
  }

  if (type === FORGOT_PASSWORD_FAIL) {
    return {
      ...state,
      // users: payload.users,
      errorMessage: {
        ...state.errorMessage,
        update: payload.errorMessage,
      },
    }
  }

  return state;
}