
import {
    SAVE_MENU_ITEMS_FAILED,
    SAVE_MENU_ITEMS_SUCCESS,
    GET_MENU_ITEMS_FAILED,
    GET_MENU_ITEMS_SUCCESS,
    DELETE_MENU_ITEMS_FAILED,
    DELETE_MENU_ITEMS_SUCCESS
} from "../types/menuTypes";

import axios from "axios";

import { SERVER_URL } from '../../config/keys';
import { current } from "@reduxjs/toolkit";

export const userAuth = () => {
    axios.interceptors.request.use(
        (config) => {
            // config.headers.authorization = `Bearer ${token}`;
            config.headers = {
                ...config.headers,
                'Content-Type': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*'
            };

            config.withCredentials = true
            // console.log(config)
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
};

const axiosInstance = axios.create({
    baseURL: `${SERVER_URL}`,
    withCredentials: true,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

export const CreateMenu = (menuData) => {
    userAuth();
    return async dispatch => {
        try {
            const response = await axios.post(`${SERVER_URL}/api-v1/menu/create-menu`, menuData);

            dispatch({
                type: SAVE_MENU_ITEMS_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    allmenus: response.data.allmenus,
                    current: response.data.current
                },
            });
        } catch (error) {
            let data = error.response?.data?.message || error.message;
            dispatch({
                type: SAVE_MENU_ITEMS_FAILED,
                payload: {
                    error: data,
                },
            });
        }
    }
}

export const UpdateMenu = (menuData) => {
    userAuth();
    return async dispatch => {
        try {
            const response = await axios.post(`${SERVER_URL}/api-v1/menu/update-menu`, menuData);

            dispatch({
                type: SAVE_MENU_ITEMS_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    allmenus: response.data.allmenus,
                    current: response.data.current
                },
            });
        } catch (error) {
            let data = error.response?.data?.message || error.message;
            dispatch({
                type: SAVE_MENU_ITEMS_FAILED,
                payload: {
                    error: data,
                },
            });
        }
    }
}

export const GetMenus = () => {
    userAuth();
    return async dispatch => {
        try {
            const response = await axios.get(`${SERVER_URL}/api-v1/menu/get-menu`);

            dispatch({
                type: GET_MENU_ITEMS_SUCCESS,
                payload: {
                    allmenus: response.data.allmenus,
                    successMessage: response.data.message,
                },
            });
        } catch (error) {
            let data = error.response?.data?.message || error.message;
            dispatch({
                type: GET_MENU_ITEMS_FAILED,
                payload: {
                    error: data,
                },
            });
        }
    };
}

export const DeleteMenu = (itemid) => {
    // console.log(itemid)
    // return;
    userAuth();
    return async dispatch => {
        try {
            const response = await axios.delete(`${SERVER_URL}/api-v1/menu/delete-menu`, {
                data: { itemid }
            });

            dispatch({
                type: DELETE_MENU_ITEMS_SUCCESS,
                payload: {
                    successMessage: response.data.message,
                    allmenus: response.data.allmenus
                },
            });
        } catch (error) {
            let data = error.response?.data?.message || error.message;
            dispatch({
                type: DELETE_MENU_ITEMS_FAILED,
                payload: {
                    error: data,
                },
            })
        }
    };
};