import axios from "axios";
import {
    SAVE_MENU_ITEMS_SUCCESS,
    SAVE_MENU_ITEMS_FAILED,
    GET_MENU_ITEMS_SUCCESS,
    GET_MENU_ITEMS_FAILED,
    DELETE_MENU_ITEMS_SUCCESS,
    DELETE_MENU_ITEMS_FAILED,
    ERROR_CLEAR,
    SUCCESS_MESSAGE_CLEAR
} from "../types/menuTypes";

import { SERVER_URL } from '../../config/keys';

// import { jwtDecode } from 'jwt-decode'

const initialState = {
    allmenus: [],
    current: null,
    successMessage: {
        create: "",
        get: "",
        metaget: "",
        update: "",
        delete: ""
    },
    errorMessage: null
};

export const MenuReducer = (state = initialState, action) => {
    const { payload, type } = action;

    if (type === SAVE_MENU_ITEMS_SUCCESS) {
        return {
            ...state,
            successMessage: {
                create: payload.successMessage,
            },
            current: payload.current,
            allmenus: [...payload.allmenus, {
                "_id": '',
                "author": false,
                "menuName": "Create new menu",
                "menuStructure": "[]"
            }],
        }
    }

    if (type === SAVE_MENU_ITEMS_FAILED) {
        return {
            ...state,
            successMessage: {
                create: payload.successMessage,
            },
        }
    }

    if (type === GET_MENU_ITEMS_SUCCESS) {
        return {
            ...state,
            // errorMessage: payload.errorMessage,
            allmenus: [...payload.allmenus, {
                "_id": '',
                "author": false,
                "menuName": "Create new menu",
                "menuStructure": "[]"
            }],
            successMessage: {
                get: payload.successMessage,
            },
        }
    }

    if (type === GET_MENU_ITEMS_FAILED) {
        return {
            ...state,
            errorMessage: payload.errorMessage,
        }
    }

    if (type === DELETE_MENU_ITEMS_SUCCESS) {
        return {
            ...state,
            successMessage: {
                delete: payload.successMessage,
            },
            allmenus: [...payload.allmenus, {
                "_id": '',
                "author": false,
                "menuName": "Create new menu",
                "menuStructure": "[]"
            }],
        }
    }

    if (type === DELETE_MENU_ITEMS_FAILED) {
        return {
            ...state,
            errorMessage: payload.errorMessage,
        }
    }

    if (type === ERROR_CLEAR) {
        return {
            ...state,
            errorMessage: null
        }
    }

    if (type === SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            successMessage: {
                create: "",
                get: "",
                update: "",
                delete: ""
            },
        }
    }

    if (type === ERROR_CLEAR) {
        return {
            ...state,
            errorMessage: null
        }
    }

    return state;
}