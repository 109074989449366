import axios from "axios";
import {
    TERM_CREATE_SUCCESS,
    TERM_CREATE_FAIL,
    TERM_GET_SUCCESS,
    TERM_GET_FAIL,
    TERM_UPDATE_FAIL,
    TERM_UPDATE_SUCCESS,
    TERM_DELETE_SUCCESS,
    TERM_DELETE_FAIL,
    ERROR_CLEAR,
    SUCCESS_MESSAGE_CLEAR,
    GET_ALL_COVARIAGE,
    GET_ALL_COVARIAGE_FAIL,
    ADD_NEW_LOCATION,
    ADD_NEW_LOCATION_FAIL,
    UPDATE_LOCATION,
    UPDATE_LOCATION_FAIL,
    DELETE_LOCATION,
    DELETE_LOCATION_FAIL
} from "../types/termTypes";

import { SERVER_URL } from '../../config/keys';

// import { jwtDecode } from 'jwt-decode'

const initialState = {
    terms: [],
    covarage: [],
    successMessage: {
        create: "",
        get: "",
        update: "",
        delete: ""
    },
    errorMessage: null
};

export const TermsReducer = (state = initialState, action) => {
    const { payload, type } = action;

    if (type === TERM_CREATE_SUCCESS) {
        return {
            ...state,
            successMessage: {
                ...state.successMessage,
                create: payload.successMessage,
            },
            terms: payload.terms
        }
    }

    if (type === TERM_CREATE_FAIL) {
        return {
            ...state,
            errorMessage: payload.errorMessage,
        };
    }

    if (type === TERM_GET_SUCCESS) {
        return {
            ...state,
            terms: payload.terms,
            successMessage: {
                ...state.successMessage,
                get: payload.successMessage,
            },
        }
    }

    if (type === TERM_GET_FAIL) {
        return {
            ...state,
            errorMessage: payload.errorMessage,
        };
    }

    if (type === TERM_UPDATE_SUCCESS) {
        return {
            ...state,
            terms: payload.terms,
            successMessage: {
                ...state.successMessage,
                update: payload.successMessage,
            },
        }
    }

    if (type === TERM_UPDATE_FAIL) {
        return {
            ...state,
            errorMessage: payload.errorMessage,
        };
    }

    if (type === TERM_DELETE_SUCCESS) {
        return {
            ...state,
            terms: payload.terms,
            successMessage: {
                ...state.successMessage,
                delete: payload.successMessage,
            },
        }
    }

    if (type === TERM_DELETE_FAIL) {
        return {
            ...state,
            errorMessage: payload.errorMessage,
        };
    }

    if (type === GET_ALL_COVARIAGE) {
        return {
            ...state,
            covarage: payload.covarages,
            successMessage: {
                ...state.successMessage,
                get: payload.successMessage,
            },
        }
    }

    if (type === GET_ALL_COVARIAGE_FAIL) {
        return {
            ...state,
            errorMessage: payload.error,
        }
    }

    if (type === ADD_NEW_LOCATION) {
        return {
            ...state,
            successMessage: {
                create: payload.successMessage,
                get: '',
                update: '',
                delete: '',
            }
        }
    }

    if (type === ADD_NEW_LOCATION_FAIL) {
        return {
            ...state,
            errorMessage: payload.error,
        }
    }


    if (type === UPDATE_LOCATION) {
        return {
            ...state,
            successMessage: {
                create: '',
                get: '',
                update: payload.successMessage,
                delete: '',
            }
        }
    }

    if (type === UPDATE_LOCATION_FAIL) {
        return {
            ...state,
            errorMessage: payload.error,
        }
    }


    if (type === DELETE_LOCATION) {
        return {
            ...state,
            successMessage: {
                create: '',
                get: '',
                update: '',
                delete: payload.successMessage,
            }
        }
    }

    if (type === DELETE_LOCATION_FAIL) {
        return {
            ...state,
            errorMessage: payload.error,
        }
    }

    if (type === SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            successMessage: {
                create: "",
                get: "",
                update: "",
                delete: ""
            },
        }
    }

    if (type === ERROR_CLEAR) {
        return {
            ...state,
            errorMessage: null
        }
    }

    return state;
}