import { Box, styled, Typography, ImageList, ImageListItem, ImageListItemBar, ListSubheader, IconButton, Modal, Grid, Paper, Tooltip, InputBase, Divider } from "@mui/material";

import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';

import { Breadcrumb, SimpleCard } from "app/components";
import Dropzone from 'react-dropzone'
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { GetPosts, CreatePost, UpdatePost, CreateMediaPost, GetMediaPosts } from "../../../store/actions/postAction"
import { GetUsers } from "../../../store/actions/userAction"
import { SUCCESS_MESSAGE_CLEAR, ERROR_CLEAR } from "../../../store/types/postTypes"
import { CLIENT_URL, SERVER_URL } from '../../../config/keys'
import Swal from 'sweetalert2'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from '@mui/icons-material/Download';

import MetaGenerator from "app/components/MetaGenerator";

const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Media = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const [openImage, setOpenImage] = useState(false);
    const [openImageData, setOpenImageData] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = (item) => {
        setOpenImage(item.postImage)
        setOpen(true)
    };

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(openImage);
        setTooltipOpen(true);
        setTimeout(() => setTooltipOpen(false), 1000); // Reset tooltip after 1 second
    };

    const handleDownload = async () => {
        // Extract the file name from the URL
        const fileName = new URL(openImage).pathname.split('/').pop();

        try {
            // Fetch the file content
            const response = await fetch(openImage);
            if (!response.ok) {
                throw new Error('Failed to fetch the file');
            }

            // Convert the response to a Blob
            const blob = await response.blob();

            // Create an object URL for the Blob
            const objectUrl = URL.createObjectURL(blob);

            // Create an anchor element and trigger the download
            const anchor = document.createElement('a');
            anchor.href = objectUrl;
            anchor.download = fileName || 'downloaded-file';
            document.body.appendChild(anchor);
            anchor.click();

            // Clean up the object URL and remove the anchor
            URL.revokeObjectURL(objectUrl);
            document.body.removeChild(anchor);
        } catch (error) {
            console.error('Error during download:', error);
        }
    };

    const handleClose = () => setOpen(false);

    const { myInfo, users } = useSelector((state) => state.Users);
    // console.log(myInfo)
    const { posts, successMessage, errorMessage } = useSelector((state) => state.Posts);

    const [post, setPost] = useState({ postTitle: "", postContent: "", postExcerpt: "", postType: "Post", postSlug: "", postStatus: "Draft", author: '' });

    useEffect(() => {
        dispatch(GetUsers()).then(() => {
            dispatch({ type: SUCCESS_MESSAGE_CLEAR })
        })
    }, []);

    let currentUser = '';

    useEffect(() => {
        if (users && users.length > 0 && myInfo && Object.keys(myInfo).length > 0) {
            currentUser = users.find(item => item.email === myInfo.email);
            setPost(post => ({
                ...post,
                author: currentUser._id
            }))
            dispatch(GetMediaPosts({ author: currentUser._id })).then(() => {
                dispatch({ type: SUCCESS_MESSAGE_CLEAR })
            })
        }
    }, [users, myInfo])

    useEffect(() => {
        if (openImage) {
            checkRemoteFile(openImage);
        }
    }, [openImage]);

    const [loading, setLoading] = useState(false);
    const [progressPercentage, setProgressPercentage] = useState(0);

    const [callbackResponse, setCallbackResponse] = useState({ type: "info", message: "" });

    const handledropUpload = (acceptedFiles) => {
        // console.log(acceptedFiles, post);
        setLoading(true);

        setInterval(() => {
            setProgressPercentage(Math.min(progressPercentage + Math.random() * 10, 100));
        }, 1000);

        dispatch(CreateMediaPost({ acceptedFiles: acceptedFiles, author: post.author })).then(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (successMessage.create) {
            setCallbackResponse({ type: "success", message: successMessage.create });
            setLoading(false);
            setTimeout(() => {
                dispatch({ type: SUCCESS_MESSAGE_CLEAR })
            }, 2000);
        }

        if (successMessage.update) {
            setCallbackResponse({ type: "success", message: successMessage.update });
            setLoading(false);
            setTimeout(() => {
                dispatch({ type: SUCCESS_MESSAGE_CLEAR })
            }, 2000);
        }

        if (errorMessage) {
            setCallbackResponse({ type: "error", message: errorMessage });
            setLoading(false);
            dispatch({ type: ERROR_CLEAR })
        }
    }, [successMessage, errorMessage])

    let vertical = 'top';
    let horizontal = 'right';

    const checkRemoteFile = async (url) => {
        try {
            // Make a HEAD request to get file metadata
            const response = await fetch(url, { method: 'HEAD' });
            if (!response.ok) {
                console.error('File does not exist:', response.status);
                return null; // File does not exist
            }

            // Extract Content-Type and Content-Length
            const contentType = response.headers.get('Content-Type');
            const contentLength = response.headers.get('Content-Length');

            // Extract the file name from the URL
            const fileName = new URL(url).pathname.split('/').pop();

            let fileDetails = {
                exists: true,
                fileName: fileName || null,
                type: contentType,
                sizeBytes: contentLength ? parseInt(contentLength, 10) : null,
                sizeKB: contentLength ? (parseInt(contentLength, 10) / 1024).toFixed(1) : null,
                sizeMB: contentLength ? (parseInt(contentLength, 10) / (1024 * 1024)).toFixed(1) : null,
                dimensions: null, // Placeholder for dimensions
            };

            // If the file is an image, fetch it to get dimensions
            if (contentType && contentType.startsWith('image/')) {
                const img = new Image();
                img.src = url;

                await new Promise((resolve, reject) => {
                    img.onload = () => {
                        fileDetails.dimensions = {
                            width: img.width,
                            height: img.height,
                        };
                        resolve();
                    };
                    img.onerror = reject;
                });
            }

            // console.log(fileDetails)
            setOpenImageData(fileDetails);
        } catch (error) {
            console.error('Error fetching file details:', error);
            return null; // Error occurred
        }
    }

    const getFileType = (url) => {
        const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"]; // List of common image file extensions
        const videoExtensions = ["mp4", "avi", "mkv", "mov", "wmv"]; // List of common video file extensions
        const audioExtensions = ["mp3", "wav", "ogg", "m4a"]; // List of common audio file extensions

        // Extract the file extension from the URL
        const matches = url.match(/\.([0-9a-z]+)(?:[?#]|$)/i);
        if (matches && matches.length > 1) {
            const extension = matches[1].toLowerCase(); // Get the file extension in lowercase

            if (imageExtensions.includes(extension)) {
                return "image"; // Return "image" if it's an image file
            } else if (videoExtensions.includes(extension)) {
                return "video"; // Return "video" if it's a video file
            } else if (audioExtensions.includes(extension)) {
                return "audio"; // Return "audio" if it's an audio file
            } else {
                return "file"; // Return "file" if it's neither image, video, nor audio
            }
        } else {
            return null; // If no file extension found, return null
        }
    }

    return (
        <Container>
            <MetaGenerator title="All media" />

            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: "Dashboard", path: "" }, { name: "Media" }]} />
            </Box>

            <Snackbar anchorOrigin={{ vertical, horizontal }} open={callbackResponse.message} autoHideDuration={6000} onClose={() => setCallbackResponse({ type: "", message: "" })}>
                <Alert
                    // onClose={setCallbackResponse({ type: "", message: "" })}
                    severity={callbackResponse.type}
                    variant="filled"
                    sx={{ width: '300px' }}
                >
                    {callbackResponse.message}
                </Alert>
            </Snackbar>

            <SimpleCard title="All Media">
                <Dropzone onDrop={acceptedFiles => handledropUpload(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                        <section style={{ textAlign: 'center', border: '2px dashed', padding: '20px', cursor: 'pointer' }}>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {loading ? (
                                    <CircularProgress />
                                ) : (
                                    <><CloudUploadIcon /><p>Drag 'n' drop some files here, or click to select files</p></>
                                )}
                            </div>
                        </section>
                    )}
                </Dropzone>

                <ImageList variant="standard" cols={3} gap={15} style={{ marginTop: "30px" }}>
                    {posts && posts.map((obj) => (
                        <>
                            {obj.posts && obj.posts.map((item) => (
                                <ImageListItem key={item._id} onClick={() => handleOpen(item)} style={{ border: "2px solid #ccc", cursor: "pointer" }}>
                                    <img
                                        srcSet={`${item.postImage}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${item.postImage}?w=248&fit=crop&auto=format`}
                                        alt={item.postTitle}
                                        loading="lazy"
                                    />
                                    <ImageListItemBar
                                        title={item.postTitle}
                                        subtitle={item.author}
                                        actionIcon={
                                            <IconButton
                                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                aria-label={`info about ${item.postTitle}`}
                                            >
                                                {getFileType(item.postImage) === 'image' ? (
                                                    <ImageIcon />
                                                ) : getFileType(item.postImage) === 'video' ? (
                                                    <VideocamIcon />
                                                ) : getFileType(item.postImage) === 'audio' ? (
                                                    <AudioFileIcon />
                                                ) : (
                                                    <TextSnippetIcon />
                                                )}
                                            </IconButton>
                                        }
                                    />
                                </ImageListItem>
                            ))}
                        </>
                    ))}
                </ImageList>
            </SimpleCard>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ width: '90%', margin: 'auto', marginTop: '5%', border: 'unset', boxShadow: 'none' }}
            >
                <Item>
                    <Grid container>
                        <Grid item xs={12} sm={8} md={8} lg={8} style={{ maxHeight: '75vh', overflowY: 'auto' }}>
                            <img src={openImage} style={{ width: '100%' }} />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <Typography id="modal-modal-title" variant="h6" component="h6">
                                Media Info
                            </Typography>
                            <Paper
                                component="form"
                                sx={{ p: '2px 4px', margin: '0 auto', display: 'flex', alignItems: 'center', width: '90%' }}
                            >

                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder={openImage ? openImage : 'Click to copy image url...'}
                                    value={openImage ? openImage : 'Click to copy image url...'}
                                    inputProps={{ 'aria-label': 'Click to copy image url...' }}
                                    readOnly={true}
                                />
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                <Tooltip
                                    title={tooltipOpen ? "Copied!" : "Copy"}
                                    open={tooltipOpen}
                                    onClose={() => setTooltipOpen(false)}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                >
                                    <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={handleCopy}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Tooltip>

                                <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={handleDownload}>
                                    <DownloadIcon />
                                </IconButton>
                            </Paper>

                            <Paper
                                component="div"
                                sx={{ p: '2px 4px', margin: '10px auto', display: 'flex', alignItems: 'center', width: '90%' }}
                            >
                                <Typography id="modal-modal-description" sx={{ mt: 2, padding: '15px', textAlign: 'justify' }}>
                                    {openImageData && Object.keys(openImageData).length > 0 ? (
                                        <>
                                            <b>File Name:</b> {openImageData.fileName}<br />
                                            <b>File Type:</b> {openImageData.type}<br />
                                            <b>File Size:</b> {openImageData.sizeKB}KB<br />
                                            <b>Dimension:</b> {openImageData.dimensions.width} x {openImageData.dimensions.height}<br />
                                        </>
                                    ) : null}
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Item>
            </Modal>
        </Container >
    );
};

export default Media;

